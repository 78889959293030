<template>
    <v-card class="card-custom">
      <v-card-title class="d-flex align-center">
  <div>
    <h4 class="mb-2 font-weight-bold text-dark">
      All Offers <br />
      <small>Click add to create a new offer or edit below.</small>
    </h4>
  </div>
  <v-spacer></v-spacer>
  <v-btn class="bg-primary text-light"
   v-if=" $hasPermission('manage business settings') "
  @click="editData({})">
    Add Offer
  </v-btn>
</v-card-title>

  
      <v-card-text>

        
        <div class="table-responsive mt-5">
          <table
  class="
    table
    table-head-custom
    table-vertical-center
    table-head-bg
    table-borderless
  "
>
  <thead>
    <tr class="text-left">
      <th style="min-width: 150px">Offer Title</th>
      <th style="min-width: 250px">Description</th>
      <th style="min-width: 100px">Discounted Price</th>
      <th style="min-width: 200px">Applicable Products</th>
      <th style="min-width: 100px">Status</th>
      <th style="min-width: 100px">Actions</th>
    </tr>
  </thead>
  <tbody>
    <template v-for="(offer, i) in offers">
      <tr :key="i">
        <td>
          <span class="text-dark-75 d-block font-size-lg">{{ offer.title }}</span>
        </td>
        <td>
          <span class="text-dark-75 d-block font-size-lg">{{ offer.description }}</span>
        </td>
        <td>
          <span class="text-dark-75 d-block font-size-lg">
            {{ $store.state.auth.currency }}{{ offer.discounted_price }}
          </span>
        </td>
        <td>
          <span class="text-dark-75 d-block font-size-lg">
            <span v-for="(item, index) in offer.offer_products">
              {{item.product.name }}({{ item.required_quantity }})<span v-if="index !== offer.offer_products.length - 1">, </span>
            </span>
          </span>
        </td>
        <td>
          <span :class="offer.is_active ? 'text-success' : 'text-danger'">
            {{ offer.is_active ? 'Active' : 'Inactive' }}
          </span>
        </td>
        <td>
          <div style="display: flex">
            <a
            v-if=" $hasPermission('manage business settings') "

              v-b-modal.modal-1
              @click="editData(offer)"
              class="btn btn-light-primary font-size-xl"
            >
              <i class="flaticon2-pen"></i>
            </a>
            &nbsp;&nbsp;
            <a
            v-if=" $hasPermission('manage business settings') "

              @click="deleteData(offer.id)"
              class="btn btn-light-danger font-size-xl"
            >
              <i class="flaticon-delete"></i>
            </a>
          </div>
        </td>
      </tr>
    </template>
  </tbody>
          </table>
         </div>

      </v-card-text>
  

      <b-modal id="modal-1" ref="voucher-model" title="Offer Details" hide-footer>
              <OfferForm
                @update="getOffers"
                :record="data"
                @hide-modal="hideModal"
              ></OfferForm>
            </b-modal>

    </v-card>
  </template>
  
  <script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import { GET_OFFERS, DELETE_OFFER } from "@/core/services/store/config.module";
  import OfferForm from "@/view/pages/business/form-components/OfferForm";
  import Swal from "sweetalert2";
  
  export default {
    name: "Offers",
    components: {
      OfferForm,
    },
    data() {
      return {
        offers: [],
        data: {},
        showModal: false,
        headers: [
          { text: "Title", value: "title", sortable: false },
          { text: "Description", value: "description", sortable: false },
          { text: "Products", value: "products", sortable: false },
          { text: "Discounted Price", value: "discounted_price", sortable: false },
          { text: "Status", value: "is_active", sortable: false },
          { text: "Actions", value: "actions", sortable: false }
        ]
      };
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Offers" },
        { title: "All Offers" },
      ]);
  
      this.getOffers();
    },
    methods: {
      editData(item) {
        this.data = item;
        console.log('data',item)
        this.$refs['voucher-model'].show();

      //  this.showModal = true;
      },
      hideModal() {
        this.$refs['voucher-model'].hide();
      },
      getOffers() {
        this.offers = [];
        this.$store
          .dispatch(GET_OFFERS)
          .then((resp) => {
            this.offers = resp.map((offer) => {
              return {
                ...offer,
                products: offer.offer_products.map((op) => ({
                  name: op.product.name,
                  required_quantity: op.required_quantity,
                }))
              };
            });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      deleteData(id) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch(DELETE_OFFER, {id:id})
              .then(() => {
                Swal.fire("Deleted!", "Offer has been deleted.", "success");
                this.getOffers();
              })
              .catch((err) => {
                console.error(err);
              });
          }
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .card-custom {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  </style>
  
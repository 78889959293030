<template>
  <div>
    <div class="form-group">
      <label>Title</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="form.title"
        placeholder="Offer Title (e.g., 3 Pizzas for $20)"
      />
      <span class="form-text text-muted">Enter the title of the offer</span>
    </div>

    <div class="form-group">
      <label>Description (Optional)</label>
      <textarea
        class="form-control form-control-solid form-control-lg"
        v-model="form.description"
        placeholder="Detailed description of the offer"
      ></textarea>
      <span class="form-text text-muted">Enter a detailed description (optional)</span>
    </div>
<template v-if="products.length==0">
  <span>Loading products</span>
  <b-row class="justify-content-center my-5">
      <b-col cols="auto">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </b-col>
      </b-row>
</template>
<template v-else>
  <div class="form-group">
      <label>Products</label>

      <select
        class="form-control form-control-solid form-control-lg"
        v-model="selectedProducts"
        multiple
      >
        <option value="" disabled>Select products</option>
        <option
          v-for="product in products"
          :key="product.id"
          :value="product"
        >
          {{ $store.state.auth.currency }}{{ product.price }} - {{ product.name }}
        </option>
      </select>
      <span class="form-text text-muted">Select the products for this offer</span>
    </div>

    <div v-if="selectedProducts.length">
      <div class="form-group" v-for="(product, index) in selectedProducts" :key="product.id">
        <label>Quantity for {{ product.name }}</label>
        <input
          type="number"
          min="1"
          class="form-control form-control-solid form-control-lg"
          v-model="product.required_quantity"
          placeholder="Required quantity for this product"
        />
      </div>
    </div>
</template>


    <div class="form-group">
      <label>Discounted Price ({{ $store.state.auth.currency }})</label>
      <input
        type="number"
        min="0"
        step="0.01"
        class="form-control form-control-solid form-control-lg"
        v-model="form.discounted_price"
        placeholder="Discounted total price"
      />
      <span class="form-text text-muted">Enter the discounted total price for this offer</span>
    </div>

    <div class="form-group">
      <label>Is Active</label>
      <b-form-checkbox v-model="isActive"> Active </b-form-checkbox>
    </div>

    <hr />

    <div class="form-group" style="float: right">
      <b-button @click="cancel">Cancel</b-button> &nbsp;&nbsp;
      <b-button @click="save">Save</b-button>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SAVE_OFFER } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "OfferForm",
  props: ["record"],
  data() {
    return {
      form: {
        business_id: 1,
        title: "",
        description: "",
        products: [],
        discounted_price: 0.0,
        is_active: 1,
        offer_id: "",
      },
      selectedProducts: [],
      products: [],
    };
  },

  computed: {
    isActive: {
      get() {
        return this.form.is_active === 1;
      },
      set(value) {
        this.form.is_active = value ? 1 : 0;
      },
    },
  },

  methods: {
    cancel() {
  this.selectedProducts = [];
  this.form = {
    business_id: 1,
    title: "",
    description: "",
    products: [],
    discounted_price: 0.0,
    is_active: 1,
    offer_id: "",
  };
  this.$emit("hide-modal");

},

    save() {
      if (!this.form.title) {
        Swal.fire("Validation!", "Title is required", "error");
        return;
      }

      if (!this.selectedProducts.length) {
        Swal.fire("Validation!", "Please select at least one product", "error");
        return;
      }

      this.form.products = this.selectedProducts.map((product) => ({
        product_id: product.id,
        required_quantity: product.required_quantity || 1,
      }));

      if (this.form.discounted_price <= 0) {
        Swal.fire("Validation!", "Discounted price must be greater than 0", "error");
        return;
      }

      this.$store
        .dispatch(SAVE_OFFER, this.form)
        .then((resp) => {
          if (resp.status === "Success") {
            Swal.fire("Information!", resp.message, "success");
            this.cancel()
            this.$emit("update");
          } else {
            Swal.fire("Error", resp.message, "error");
          }
        })
        .catch((error) => {
          Swal.fire("Error", error.response?.data?.message || "An error occurred", "error");
        });
    },
    fetchProducts() {
  ApiService.get("business/menu/products")
    .then((response) => {
      this.products = response.data.data;

      if (this.record.id) {
        this.selectedProducts = this.record.offer_products.map((p) => {
          let matchedProduct = this.products.find((product) => product.id === p.product_id);
          
          if (matchedProduct) {
            // Ensure the required_quantity is set
            matchedProduct.required_quantity = p.required_quantity;
            return matchedProduct;
          } else {
            // Handle case where the product is not found in the list
            return {
              id: p.product_id,
              name: p.product?.name || "Unknown Product",
              price: p.product?.price || 0,
              required_quantity: p.required_quantity, // Ensure this is correctly set
            };
          }
        });
      }
    })
    .catch((error) => {
      console.error("Failed to fetch products", error);
    });
},
  },

  // watch: {
  //   selectedProducts: {
  //     handler() {
  //       this.form.products = this.selectedProducts.map((product) => ({
  //         product_id: product.id,
  //         required_quantity: product.required_quantity || 1,
  //       }));
  //     },
  //     deep: true,
  //   },
  // },

  mounted() {
  this.fetchProducts();

  // Only populate form if editing an existing record
  if (this.record.id) {
    this.form.offer_id = this.record.id;
    this.form.business_id = this.record.business_id;
    this.form.title = this.record.title;
    this.form.description = this.record.description;
    // this.selectedProducts = this.record.offer_products.map((p) => ({
    //   id: p.product_id,
    //   name: p.product?.name || "",
    //   price: p.product?.price || 0,
    //   required_quantity: p.required_quantity || 1,
    // }));
    this.form.discounted_price = this.record.discounted_price;
    this.form.is_active = this.record.is_active;
  } else {
    // Reset form for new offer
    this.selectedProducts = [];
    this.form = {
      business_id: 1,
      title: "",
      description: "",
      products: [],
      discounted_price: 0.0,
      is_active: 1,
      offer_id: "",
    };
  }
},

};
</script>
